
.container-login{
  width:750px !important;
  margin:0 auto;
  height: 400px;
}

.login-left {
  background-color:#fafafa;
  border-radius: 5px;
}

.login-left .app-detail{
  margin-top:20px;
}


.login-right{
  background-color:#fff;
  border-radius: 5px;
  z-index:999;
  
  h2{
    background-color:#fff;
  color:#444444 ;
  text-align:center ;
  margin:0 !important;
  margin-bottom:20px !important;

  }
}

.medical-image{
width:100%;
height: auto;
}

.logo-image{
width:180px;
height: auto;
padding:30px 0;
text-align: center;
}

.login-wrapper{   
width:80%;
margin:auto;
text-align: left;
position: relative;
padding:40px 10px 50px;
// top: 50%;
// transform: translateY(-50%);
// -webkit-transform: translateY(-50%);

.login-header{
 text-align: center;
 padding-bottom:10px;

//  .logo-image{
//    padding:20px 0 15px;
//  }

 p{
    font-size: 16px;
    // font-weight: 500;
    color:$gray-700;
     margin-bottom:8px;
    
 }
}

.field-wrapper{
  min-height:64px;
}

}

.login-form{
display: flex;
flex-direction: column;



.btn{
  height:36px;
}
}


p {

letter-spacing: 1px;
font-size:14px;
color: #333333;
}

.login-back{
position: absolute;
bottom:0;
right:10px;
padding:10px;
}

.header-login {
position:relative;
text-align:center;
// background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
// background: linear-gradient(60deg, rgba(#0f2e90,1) 0%, rgba($cogent,0.1) 100%);
// background: linear-gradient(60deg, #543ab7 0%, #00acc1 100%);
//background: linear-gradient(60deg, $cogent 0%, #2e5ade 100%);
background: linear-gradient(40deg, $cogent 30%, #2e5ade 100%);
color:white;
}
.logo {
width:50px;
fill:white;
padding-right:15px;
display:inline-block;
vertical-align: middle;
}

.inner-header {
height:calc(85vh - 50px);
width:100%;
margin: 0;
padding: 0;
}

.flex { /*Flexbox for containers*/
display: flex;
justify-content: center;
align-items: center;
text-align: center;
flex-direction:column;
}

.company-logo{
  width:40px;
  height: auto;
  display: none;
}

// .ribbon{
//   position: absolute;
//   top:20px;
//   left:-40px;
//   background-color: $primary;
// }

//////////////////
//for new password 
/////////////////

.new-password{

  .login-wrapper{
    padding:60px 10px;
  }

  .login-header{
    display: none;
  }


  .login-page-title{
    text-transform: capitalize;
    color:darken($cogent,5%);
    text-align: center;
    font-weight: 400;
    font-size:19px;
    margin:4px 0 24px;
   }

   .verify-wrapper{
     padding:90px 10px;
   }

   .forgot-password-content{
     font-size:1rem;
     letter-spacing:0;
     margin-bottom:20px;
   }

   #login-page-redirect{
     line-height: 1.5;
   }

   .change-email-verify{
    font-size: 1rem;
    letter-spacing:0;
    margin-bottom:20px;
   }

   p{
    font-size:1rem;
    letter-spacing: 0;
   }
   
   .password-requirement{
     // display:none;
     margin-bottom:16px;
     p{
       margin-bottom:2px;
       font-weight: 300;
       margin:0px 0;
     }
     
   ul{
     
     li{
       list-style-type: disc;
       color:$gray-600;
       font-size:12px;
       margin-left: 16px;
       font-weight: 300;
     }
   }
     
   }

}

.forgot-password{
  .login-header{
    display: block;
    .login-page-title{
     
      font-size: 1rem;
      text-transform: capitalize;
      color:$cogent;
      text-align: center;
      font-weight: 400;
      font-size: 19px;
      margin: 4px 0 24px;
    }
  }
}


.error{
  color:$danger;
  margin-bottom:6px;
  
}


//////////////////
//for change password modal  
/////////////////

.change-password{
  .password-requirement{
    display:none;
  }
}

// .logo-wrapper{
// background-color:$gray-100;
// width:48px;
// height: 44px;
// padding:2px;
// display: inline-block;
// border:1px solid $gray-400;
// @include border-radius(50%);

// }

/////////////////Background css/////////////////




.waves {
position:relative;
width: 100%;
height:15vh;
margin-bottom:-1px; /*Fix for safari gap*/
  transform: scaleY(2);
// min-height:100px;
// max-height:150px;
}
.waves-up{
  position: relative;
  transform: scaleY(1.5);
  width: 100%;
  height:15vh;
}

.content-login {
position:relative;
height:40px;
text-align:center;
background-color: transparent;
}

/* Animation */

.parallax > use {
animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
}
.parallax > use:nth-child(1) {
animation-delay: -2s;
animation-duration: 7s;
}
.parallax > use:nth-child(2) {
animation-delay: -3s;
animation-duration: 10s;
}
.parallax > use:nth-child(3) {
animation-delay: -4s;
animation-duration: 13s;
}
.parallax > use:nth-child(4) {
animation-delay: -5s;
animation-duration: 20s;
}

@keyframes move-forever {
0% {
 transform: translate3d(-90px,0,0);
}
100% { 
  transform: translate3d(85px,0,0);
}
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
.inner-header{
  height:calc(100vh - 90px);
}
  .container-login{
    width:90% !important;
    margin:0 auto;
    min-height: 400px;
  
  
  }
.waves {
  height:40px;
  min-height:40px;
}
.content {
  height:30vh;
}
h1 {
  font-size:24px;
}
}

//** ribbon **//
.box {
  width: 200px; height: 300px;
  position: relative;
  border: 1px solid #BBB;
  background: #EEE;
}
.ribbon {
  position: absolute;
  left: -6px;
   top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: regular;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: $primary;
  // background: linear-gradient(#0043ffd9 0%, #0063ff 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; 
  right: -4px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid $primary;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid $primary;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid $primary;
  border-bottom: 3px solid transparent;
  border-top: 3px solid $primary;
}




