.table {
    --bs-table-color: #595d6e;
    --bs-table-bg: transparent;
    --bs-table-border-color: #dee2e6;
    --bs-table-accent-bg: rgba(0, 0, 0, 0.00);
    --bs-table-striped-color: #595d6e;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #595d6e;
    --bs-table-active-bg: rgba(0, 0, 0, 0.075);
    --bs-table-hover-color: #595d6e;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color);
}