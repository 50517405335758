ul {
  padding: 0;

  li {
    list-style: none;
  }
}

@media (hover: hover),
(-ms-high-contrast: none) {

  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #303644;
  }
}

@media (min-width: 768px) {

  .c-main > .container-fluid,
  .c-main > .container-sm,
  .c-main > .container-md,
  .c-main > .container-lg,
  .c-main > .container-xl,
  .c-main > .container-xxl {
    padding: 10px;
  }
}

@media (min-width: 768px) {

  .c-main > .container-fluid,
  .c-main > .container-sm,
  .c-main > .container-md,
  .c-main > .container-lg,
  .c-main > .container-xl,
  .c-main > .container-xxl {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.c-main {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding-top: 0.2rem;
}

.c-subheader-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  list-style: none;
  border-radius: 0;
  border-bottom: 1px solid;
  background-color: transparent;
  border-color: #d8dbe0;
}

.btn-dark {
  color: #fff;
  background-color: #303644;
  border-color: #303644;
}


.add-search {
  display: flex;
  gap: 0.5rem;
  margin-left: 0px;
  padding: 0;
}

.search-filter {
  display: flex;
  margin-top: 0px;
  margin-left: 2px;
  padding: 0
}

hr {
  margin-top: -10px;
  margin-bottom: 1rem;
  border: 0;
  /* border-top: 1px solid; */
  border-color: rgba(0, 0, 21, 0.2);
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid;
  background-color: #fff;
  border-color: #d8dbe0;
}

.c-header .c-subheader {
  margin-top: -3px;
  border-top: 1px solid #d8dbe000;
}

.pl-3,
.px-3 {
  padding-left: 0rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0rem !important;
}

.c-header-with-subheader {
  border: none;
}

.c-header .c-subheader {
  margin-top: 0px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  marign-bottom: -5px
}

.form-control:focus {
  color: #768192;
  background-color: #fff;
  /* border-color: #04f096; */
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(43, 43, 84, 0.5);
}

.btn-primary {
  color: #fff;
  background-color: #303644;
  border-color: #303644;
}

.form-control:focus {
  color: #768192;
  background-color: #fff;
  border-color: #303644;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(43 43 84 / 10%);
}

.css-yk16xz-control:focus {
  color: #768192;
  background-color: #fff;
  border-color: #303644;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(43 43 84 / 10%);
}

.select-wrapper .select-label.active {
  color: #14253c
}

.field-wrapper.myInput input:not([disable]) ~ .field-placeholder {
  color: #14253c;
  transform: scale(1) translateY(-18px) translateX(-6px);
  font-size: 0.6rem;
}

.btn-primary {
  color: #fff;
  background-color: #303644;
  border-color: #303644;
  border-radius: 5px;
  padding: 0.75rem 2rem;
  line-height: .6;

  &:hover {
    background-color: #384765;
  }
}

.c-sidebar {
  position: relative;
  display: flex;
  flex: 0 0 256px;
  flex-direction: column;
  order: -1;
  width: 221px;
  padding: 0;
  box-shadow: none;
}

@media (min-width: 992px) and (min-width: 992px) {

  html:not([dir=rtl]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  html:not([dir=rtl]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 220px;
  }
}

.c-header {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  min-height: 56px;
  height: 56px;
  background-color: #EDF1F5;
  border: none
}

html:not([dir=rtl]) .pagination {
  padding-left: 20px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #303644;
  border-color: #303644;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #303644;
  border-color: #303644;
  box-shadow: 0 0 0 0.2rem rgba(109, 112, 111, 0.5);
}

.c-wrapper:not(.c-wrapper-fluid) .c-header-fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-left: 10px;
}

.bg-white {
  background-color: #ffffff !important;
  border: none;
  border-radius: 3px;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #626979;
  border-color: #626979;
}

.btn-outline-secondary {
  color: #4f5d73;
  background-color: #ced2d8;
  border-color: #ced2d8;

  &:hover {
    color: #fff;
    background-color: #707070;
    border-color: #ced2d8;
  }
}

.search-filter-item li:first-child button {
  padding-left: 0;
  padding: 10px 17px;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #303644;
}

.field-wrapper.myInput input:not([disable]) ~ .field-placeholder {
  // color: $cogent;
  color: #303644;
  -webkit-transform: scale(1) translateY(-20px) translateX(-6px);
  transform: scale(1) translateY(-20px) translateX(-6px);
  font-size: 0.65rem;
  font-weight: 500;

}

.ag-theme-balham .ag-header {
  background-color: #f5f7f7;
  color: #222c4a;
  font-weight: 600;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  border-bottom: 1px solid #BDC3C7;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #222c4a;
  border: none white;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid white;
  border-bottom-color: white;
  border: none;
}

.table tbody tr {
  line-height: 22px;

  &:hover {
    background-color: #f1f2f2;
  }
}

.table th,
.table td {
  padding: 10px;
  vertical-align: top;
  border-top: 1px solid;
  border-top-color: #d8dbe0;
}

.btn-group,
.btn-group-vertical {
  position: absolute;
  display: inline-flex;
  vertical-align: top;
  margin-top: -6px;
  margin-bottom: -6px;
}

.table-dropdown {

  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    color: var(--bs-btn-active-color);
    background-color: red;
    border-color: red;
  }

  color: none;

  &:hover {
    color: #222c4a;
    background-color: none;
  }

  &:focus {
    color: none;
    box-shadow: none;
  }

  &:active {
    border: none;
    box-shadow: none;
    color: none;
    position: absolute
  }
}

// Here you can add other styles
.c-main {
  background-color: #edf1f5;
}

.c-sidebar-minimizer {
  color: antiquewhite;
}

//.c-sidebar-nav-dropdown-items {
//  background-color: #028d58;
//}

.c-header {
  background-color: #edf1f5;
  border-style: none;
}

.c-subheader {
  background-color: #edf1f5;
  border-top-style: none;
  border-style: none;
}

.c-footer {
  background-color: #edf1f5;
  border-style: none;
}

.tableCss {
  height: 1000px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #303644;
}

input:focus + .slider {
  box-shadow: 0 0 1px #303644;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkbox-container {
  padding: 15px 10px;
  border: 1px solid #cacaca;
  border-radius: 5px;
}

.left-tile {
}

.right-tile {
  .small-card {
    display: flex;
    flex-direction: row;
    align-items: center;

    .small-img {
      width: 40%;
      text-align: center;

      img {
        width: 60px;
        height: auto;
        // margin-right: 10px;
      }
    }

    .small-card-detail {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      line-height: 25px;

      .number {
        font-size: 25px;
        font-weight: 700;
      }
    }
  }
}

//.tabs {
//  display: flex;
//  flex-direction: row;
//  justify-content: space-evenly;
//  .tab {
//    width: 30%;
//    margin: 10px 0;
//    .information-detail {
//      display: flex;
//      flex-direction: row;
//      align-items: center;
//      .text {
//        width: 50%;
//        font-weight: 600;
//        line-height: 2rem;
//      }
//    }
//  }
//}

.card-header {
  // border-radius: 1rem;
  border-style: none;
  background-color: #3d4c6b;
  color: #fff;

  &:first-child {
    border-radius: 1rem 1rem 0 0;
    border-style: none;
  }
}

.card-body {
  .table-header {
    padding: 10px 10px;
  }
}

.multi-tabs {
  .card {
    border-radius: 0 0 1rem 1rem;
  }

  .nav {
    background-color: #fff;
    border-style: none;
    border-radius: 1rem 1rem 0 0;
    margin-bottom: 2px;
    justify-content: space-around;
  }

  .nav-item {
    background-color: #fff;
    border-radius: 1rem 1rem 0 0;

    .active {
      border-radius: 1rem 1rem 0 0;
      color: black;
      background-color: #fff;

      &::after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 100%;
        padding-top: 5px;
        border-bottom: 2px solid #384765;
      }
    }
  }

  .nav-link {
    color: gray;
    border-style: none;

    &:hover {
      border-style: none;
    }
  }
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid white;
}

.actions-table {
  display: block;
  box-sizing: border-box;
  width: inherit;

  .dropdown-toggle {
    border-radius: 0px;
    padding: 0;
    text-align: center;
    padding: 0.6rem 1rem 0.6rem 0.6rem;

    &:focus,
    &:hover {
      outline: 0;
      border: none;
    }

    &::after {
      display: none
    }
  }

  .dropdown-menu {
    padding: 0;
    border-radius: 2px !important;
    min-width: auto;
    z-index: 999999;
    box-shadow: 0 0 4px 0px #AFB7C0FF;
    border: none;
  }

  .dropdown-item {
    padding: 0.6rem 1rem 0.6rem 0.6rem;
    border-bottom: 1px solid #303644;
    line-height: 0.9rem;
    text-transform: capitalize;
    display: flex;
    z-index: 999999;

    &:hover {
      background-color: #303644;
      color: #fff;
    }

    .material-icons {
      font-size: 14px;
      margin-right: 6px;

    }

    svg {
      width: 15px;
      height: auto;
      margin-top: -2px;
      margin-right: 5px;

      &:after {
        display: none;
      }
    }
  }
}

.search {
  margin-top: -28px;
}

.dashboard__cardRow {
  align-items: center;
  margin-left: 20px;
}

.btn-primary {
  color: white;
  background-color: #556277;
  border-color: #4b576b;

  &:hover {
    background-color: #9DA5B1;
    color: black;
    border-color: #9DA5B1;
  }
}

//.btn-secondary {
//  color: white;
//  background-color: #01A768;
//  border-color: #01A768;
//  border-radius: 1rem;
//
//  &:hover {
//    background-color: #9DA5B1;
//    color: black;
//    border-color: #9DA5B1;
//    border-radius: 1rem;
//  }
//}

.table {
  td {
    cursor: pointer;
  }
}

.react-time-picker {
  //box-sizing: border-box;

  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: none;
  }

  .react-time-picker__inputGroup__input {
    min-width: 2em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
}


.rmdp-input {
  border: 1px solid #c0c4d6;
  border-radius: 3px;
  height: 35px;
  margin: 1px 0;
  padding: 2px 5px;
  width: 368px;
  color: rgb(128, 136, 145)
}

.circular_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover
}

.c-sidebar {
  color: #fff;
  background: #3c4b64;
}

.c-sidebar-brand {
  text-decoration: none;

  .c-sidebar-brand-full {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px;
    //border-radius: 100%;
    object-fit: fill;
    background-color: #4b576b;
    color: #fff;
    text-decoration: none;

    .side-img {
      width: auto;
      height: 40px;
      border-radius: 8px;
      margin-left: 8px;
    }

    .side-text {
      display: inline-block;
      font-size: 16px;
      font-weight: 500;
      text-decoration: none;
      margin-left: 8px;
    }
  }

  .c-sidebar-brand-minimized {
    display: flex;
    align-items: center;
    width: 100%;
    height: 55px !important;
    background-color: #4b576b;
    color: #fff;

    .side-img {
      width: 100%;
      height: auto;
      //margin-top: 3px;
      //margin-left: 2px;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  min-height: 280px;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #fff;
  border-color: #d8dbe0;
}

.c-sidebar-brand .c-sidebar-brand-full {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  object-fit: fill;
  background-color: #3c4b64;
  color: #fff;
  text-decoration: none;
}

.add-search {
  display: flex;
  gap: 0.5rem;
  margin-left: 0px;
  padding: 0;
}

.search-filter {
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
  pading: 0
}

hr {
  margin-top: -10px;
  margin-bottom: 1rem;
  border: 0;
  /* border-top: 1px solid; */
  border-color: rgba(0, 0, 21, 0.2);
}

.container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: -12px;
}

.DashboardUi .card {
  border-radius: 5px;
  //box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  transition: 5ms;
  margin: 20px !important;

}

.DashboardUi .card:hover {
  border-radius: 5px;
  box-shadow: none;
}

.DashboardUi .card .card-body {
  align-items: center;
  text-align: center;
  transition: 5ms;
  padding: 0;
  display: block;
  overflow: hidden;
  position: relative;
}

.DashboardUi .card .card-body .icon {
  //color: #1F9F54;
  height: 50px;
  width: 50px;
  line-height: 100px;
  border-radius: 225px;
  transition: 5ms;
}

.DashboardUi .card .card-body img {
  //box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  background-color: #d7e9de61;
  border: 1px solid #d7e9de61;
  width: 100% !important;

  transition: 0.6s;
}

.DashboardUi .card .card-body:hover img {
  //box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  background-color: #fff;
  border: 1px solid #ee821b;
  transform: scale(1.2);
}

.DashboardUi .card .card-body:hover .icon {
  color: #ee821b;
  height: 50px;
  width: 50px;
  line-height: 100px;
  border-radius: 225px;

}

.DashboardUi .btn {
  //background-color: #1F9F54;
}

.DashboardUi .card .card-body .icon:not(.icon-c-s):not(.icon-custom-size).icon-lg .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {

}

.DashboardUi .c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {

  width: 5rem !important;
  height: 5rem !important;
  font-size: 1.25rem;
}

.secondR {
  margin-top: 100px !important;
}

.DashboardUi .card .service-name {
  background-color: #ee821b;
  bottom: -46px;
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1;
  height: 50px;
}

.DashboardUi .card .service-name svg {
  height: 4rem !important;
  position: absolute;
  width: 4rem !important;
  line-height: 75px;
  z-index: -1;
  padding: 14px;
  bottom: 29px;
  background-color: #ee821b;
  color: #fff;
  border-radius: 50%;
  top: -18px;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-45%) translateY(-50%);
  transition: 0.6s;

}

.DashboardUi .card:hover svg {
  -webkit-transform: translateX(-50%) translateY(-60%);
  -moz-transform: translateX(0%) translateY(-60%);
  -ms-transform: translateX(0%) translateY(-60%);
  -o-transform: translateX(0%) translateY(-60%);
  transform: translateX(-45%) translateY(-60%);
}

.DashboardUi .card h5 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  //text-transform: uppercase;
  margin-top: 12px;
}

.title {
  color: black;
  padding-top: 10px;
}

.card-header {
  margin-top: -10px;
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1rem solid;
  background-color: #fff;
  border-color: #ffffff;
}

#save-profile-add {
  color: #fff;
  background-color: #303644;
  border-color: #303644;
  border-radius: 5px;
  padding: 4px 20px;
  line-height: 0.6;
}

.add-container-area {
  .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 0;
    margin-left: 0;
    border-radius: 5px;
  }
}

.add-info {
  margin-bottom: 15px;

  .label-text {
    display: inline-block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 12px;
    color: #303644
  }

  .btn:focus-visible {
    background: white;
    box-shadow: none;
  }

  .btn:hover {
    background: #303644;
    color: white;
  }

  .btn:focus {
    box-shadow: none;
  }

  .field-wrapper {
    position: relative;
    min-height: 50px;
    height: 40px;
  }
}

#manage-tab {
  btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #012141;
    border-color: #011334;
  }

  .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgb(40 136 99 / 40%);
  }

}

.nav-tabs {
  animation: none;
  border-bottom: 0px solid;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  /* border: solid; */
  border-radius: 11px;

}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #223044;
  background-color: #EDF1F5;
  border-bottom: solid #223044;
  font-weight: bold;

  &:hover {
    font-weight: bold;
  }
}

.nav-tabs .nav-link {
  color: #223044;
  border: none;
  border-bottom: #223044;
  border-bottom: solid gray;
  margin-bottom: 5px;
}

.top-tab {
  .nav-tabs {
    animation: none;
    border-bottom: 0px solid;
  }

  .nav-tabs .nav-item {
    margin-bottom: -1px;
    /* border: solid; */
    border-radius: 11px;

  }

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #223044;
    background-color: #EDF1F5;
    border-bottom: solid #223044;
    font-weight: bold;

    &:hover {
      font-weight: bold;
    }
  }

  .nav-tabs .nav-link {
    color: #223044;
    border: none;
    border-bottom: #223044;
    border-bottom: solid gray;
    margin-bottom: 5px;
  }

  .nav-tabs .nav-link:hover {
    border-bottom: solid #223044;
    color: #223044;
    font-weight: 400;
  }

  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #202b3b #1a273d #0e1a2a;
  }
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

div#container textarea {
  min-width: 270px;
  width: 270px;
  height: 22px;
  line-height: 24px;
  min-height: 22px;
  overflow-y: hidden; /* fixes scrollbar flash - kudos to @brettjonesdev */
  padding-top: 1.1em; /* fixes text jump on Enter keypress */
}

.soBalance {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.front {
  display: flex;
  flex-direction: column;

  .frontTiles {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;

    .cardTiles {
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
      width: 100%;
      //min-height: 40px;
      justify-content: space-evenly;
      align-items: center;
      margin-right: 10px;
      background-color: white;
      border: 1.5px solid #00d725;
      color: black;
      border-radius: 5px;

      .card-tiles-content {
        align-items: center;
        display: flex;
        flex-direction: column;
      }

      .frontTotal {
        font-size: 22px;
        color: rgba(0, 0, 0, 0.54);
      }

      .frontTitle {
        font-size: 15px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.65);
      }

      .frontAmount {
        font-size: 20px;
        color: rgba(0, 0, 0, 0.54);
        margin-bottom: 5px;
      }
    }

    .cardTiles1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 20%;
      height: 100%;
      background-color: #04c098;
      color: white;
      border-radius: 5px;
      margin-right: 10px;

      .frontTotal {
        font-size: 25px;
        font-weight: bolder;
        color: white;
      }

      .frontTitle {
        font-size: 15px;
        font-weight: bold;
        color: white;
        margin-bottom: 5px;
      }

      .frontAmount {
        font-size: 15px;
        font-weight: bold;
        color: white;
        margin-bottom: 5px;
      }
    }

    .cardTiles2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      height: 100%;
      margin-right: 10px;
      background-color: #156507;
      color: white;
      border-radius: 5px;
      margin-right: 10px;
      justify-content: center;

      .frontTotal {
        font-size: 25px;
        font-weight: bolder;
        color: white;
      }

      .frontTitle {
        font-size: 15px;
        font-weight: bold;
        color: white;
      }

    }

    .cardTiles3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 30%;
      height: 100%;
      background-color: #942323;
      color: white;
      border-radius: 5px;

      .frontTotal {
        font-size: 25px;
        font-weight: bolder;
        color: white;
        margin-bottom: 5px;
      }

      .frontTitle {
        font-size: 15px;
        font-weight: bold;
        color: white;
      }
    }
  }
}

.search-and-client {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  margin-bottom: 10px;

  .client-and-project {
    display: flex;
    flex-direction: row;
    width: 50%;

    .card {
      width: 33.33%;
      display: flex;
      flex-direction: row;
      margin-right: 2px;
      justify-content: space-evenly;
      align-items: center;
      padding: 2px;
      background-color: #ffffff;
      color: #575757;
      margin-bottom: 5px;
    }
  }

  .search-dashboard {
    width: 50%;
    display: flex;
    flex-direction: row;

    .field-wrapper {
      padding: 2px;
      position: static;
      width: 100%;
      min-height: 0;
    }

    .react-datepicker-wrapper {
      margin-top: -1px;
    }

    #select-wrapperdashboard {
      margin-top: -1px;
    }
  }

}

.table-and-chart {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
  height: 240px !important;
  margin-bottom: 10px;

  .table-content {
    background-color: white;
    display: flex;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    margin-right: 10px;
    border-radius: 5px;
    padding: 5px
  }

  .chart-content {
    background-color: white;
    display: flex;
    width: 50%;
    border-radius: 5px;
    height: 100%
  }
}

.login-main {
  display: flex;
  flex-direction: column;
  background-color: blueviolet;
  justify-content: space-between;
  min-height: 100vh;
  height: 100vh;
  padding-top: 5%;

  .client-login {
    display: flex;
    justify-content: center;
    align-items: center;

    .card {
      width: 40%;
    }
  }
}

.content-side-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 0;

}

.side-tab {
  position: fixed;
  margin-bottom: 4px;

  .sidebar {
    font-size: 12px;
    max-height: 100%;
    overflow: auto;
    position: fixed;
    overflow-y: scroll;
  }

  .list-group {
    text-align: inherit;
    color: #5f5e5e;

    .list-group-item {
      &:hover {
        background-color: grey;
        color: white;
      }
    }

    .list-group-item-action:focus {
      z-index: 1;
      text-decoration: none;
      color: #ffffff;
      background-color: #918e8e;
    }

    .list-group-item.active {
      z-index: 2;
      color: #fff;
      background-color: #363636;
      border-color: #363636;
    }

    .list-group-item-action:active {
      color: white;
      background-color: #363636;
    }
  }
}

.top-add-menu-design {
  position: fixed;
  overflow-y: scroll;
  background-color: white;
}

.top-button {
  padding: 2px;
  background-color: white;
  color: #5f5e5e;
  border: none;
  font-size: 12px;

  &:hover {
    background-color: grey;
    color: white;
    border-radius: 5px;
    padding: 2px;
  }
}

.main-con {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.field-wrapper {
  position: relative;
  min-height: 49px;
}

.select-wrapper .select-label {
  position: absolute;
  top: -8px;
  left: 12px;
  z-index: 2;
  background-color: white;
  font-size: 0.5rem;
  padding: 0 5px;
}

.main-report {
  padding: 10px;
  background-color: white;

  .report-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;

    .report-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .report-table {
    margin:20px;
    .table thead > tr > th {
      border: 1px solid #000000;
    }
    .table > tbody {
      vertical-align: inherit;
      border: 1px solid lightgray;
    }
    .table th, .table td {
      padding: 10px;
      vertical-align: top;
      border-left: 1px solid black;
      border-right: 1px solid black;
    }

    .report-inside-table {
      border: 1px solid grey;
      .table thead > tr > th {

      }
    }
  }

}

.add-info .label-text {
  display: inline-block;
  margin-bottom: 0.2rem;
  font-weight: 500;
  font-size: 12px;
  color: #303644;
}

.client-projects {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;

  .card {
    height: 80px;
    width: 33.33%;
    display: flex;
    flex-direction: column;
    margin-right: 2px;
    justify-content: space-evenly;
    align-items: center;
    padding: 2px;
    background-color: rgb(255, 255, 255);
    color: #575757;
    margin-bottom: 5px;
  }
}
.sales-bill {
  .sales-bill-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .sales-information{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    .customer-information{
      display: flex;
      width:60%;
      flex-direction: column;
      justify-content: flex-start;
    }
    .transaction-date{
      display: flex;
      flex-direction: column;
      width: 40%;
    }
  }
  .item-description{
    .method-payment{
      display: flex;
      width: 80%;
      justify-content: right;
      margin-bottom: 5px;
    }
    .item-table{
      .table th, .table td {
        padding: 10px;
        vertical-align: top;
        border: 0.5px solid black;
      }
    }
  }
  .in-words{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
  }
  .authorized-signature{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
.c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle {
  display: flex;
  flex: 1 1;
  align-items: center;
  padding: 1rem 1rem;
  text-decoration: none;
  white-space: nowrap;
  transition: background 0.3s, color 0.3s;
}
.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
  height: 26px;
}
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link, .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
  background: #222c4a;
}
.c-sidebar-nav-item{
  :hover{
    background: #222c4a;
  }
}
.annex13-table{
    .table th, .table td{
      padding: 7px;
      vertical-align: top;
      border: 0.5px solid black;
    }
}