
@import "react-datepicker/src/stylesheets/datepicker.scss";
@import '~bootstrap/scss/functions';

//custom colors
@import "~@ams-fe/common-sass/src/scss/variables/custom-variables";

// // Customized Bootstrap variables
@import "~@ams-fe/common-sass/src/scss/variables/custom-bootstrap-variable";
@import '~bootstrap/scss/mixins';

$datepicker__selected-color: red !important;



.react-datepicker-popper{
    z-index: 115 !important;
}

.react-datepicker-popper[data-placement^="bottom"]{
     margin-top:-28px !important;
}



 .react-datepicker__current-month{
        display: none !important;
    }

.react-datepicker-wrapper {


.field-wrapper{
    position: relative;

    &:before{
        font-family: 'FontAwesome';
        top: 10px;
        right: 10px;
        content: "\f073";
        position: absolute;
        z-index: 2;
        // color:red;
    }

    &.myInput{
        &:before{
            color:$cogent;
        }
    }

    .form-control[readonly] {
        background-color: $white;
    }


}

}

.time-picker{
    .react-datepicker-popper[data-placement^="bottom"]{
        margin-top:8px !important;
    }
    .react-datepicker-wrapper .field-wrapper,.react-datepicker-wrapper input{
        position: relative;

        &:before{
            content: "\e192";
            font-family: "Material Icons";
            // content:"\f017";
            // font-family: "FontAwesome" ;
            top: 10px;
            right: 10px;

            position: absolute;
            z-index: 5;

        }

        &.myInput{
            &:before{
                color:$cogent;
            }
        }

    }

}

// .fa-calendar{
//     position: absolute;
//     right:16px;
//     margin-top:12px;
//     z-index: 0;
// }

.react-datepicker__time-container {


    .react-datepicker__time {

      .react-datepicker__time-box {


          li.react-datepicker__time-list-item {


            &--selected {
              background-color: $cogent;
              color: white;
              font-weight: bold;
              &:hover {
                background-color: $cogent !important;
              }
            }
            &--disabled {
            //   color: $datepicker__muted-color;

              &:hover {
                cursor: default;
                background-color: transparent;
              }
              }
            }
        }
    }
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {

    background-color: $cogent;

}

// .form-control[readonly]{
//      background-color:$gray-100 !important;
// }

.dashboard-wrapper .doctor-revenue .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .dashboard-wrapper .doctor-revenue .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
     margin-left:0px;
}


