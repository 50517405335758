::-webkit-scrollbar {
  width: 6px;
  height:6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background:$gray-400;
  border-radius:8px;
  height: 50px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray-500;
  cursor: pointer;
}