
.select-wrapper{
  .select-label {
    color: $gray-600 !important;
    position: absolute;
    top: -8px;
    left: 12px;
    z-index: 2;
    background-color: white;
   font-size:0.65rem;
    padding:0 5px;
    @include border-radius(4px);
  }
  
  .select-label.active {
    color:lightgray;
  }

  .css-yk16xz-control{
    border-color:$cogent-border;
    &:hover{
      border:1px solid $gray-500;
    }
  }

  .css-1pahdxg-control{
    border-color:$gray-100;
  }

    //for disabled select
  .css-1fhf3k1-control{
     background-color:black !important;


      // cursor: not-allowed; 
   
  }

  .css-1n7v3ny-option{
    //background-color:#DEEBFF;
    background-color: #3C4B64;
    color:white
  }

  .css-1wa3eu0-placeholder{
    color:black;
    font-weight: 300;

  } 
  .css-2b097c-container {
    position: relative;
    box-sizing: border-box;
    color: black;
}

.css-1uccc91-singleValue {
  color: hsl(0deg 2% 36%);
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
}
  
}
  