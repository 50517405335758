
 @import '~bootstrap/scss/functions';

 //custom colors
 @import "~@ams-fe/common-sass/src/scss/variables/custom-variables";

// // Customized Bootstrap variables
 @import "~@ams-fe/common-sass/src/scss/variables/custom-bootstrap-variable";
 @import '~bootstrap/scss/mixins';


 .ag-root-wrapper{
    .patient-column{
        margin-top:0.1rem;
        margin-bottom:0.3rem;

         li{
            line-height:20px;
            font-weight: 500;
            padding:0;

            .badge{
                height: auto !important;
            }



            &:nth-child(2){
                font-size: 0.75rem;
                font-weight: 500;
                // padding:0 3px;
                font-weight: $gray-300;
                color:$gray-600;
                span{
                    // border:1px solid $gray-400;
                    // background-color:$gray-100;
                    font-size: 0.7rem;
                    font-weight: 500;
                    // padding:0 3px;
                    font-weight: $gray-300;
                    color:$gray-600;
                    // @include border-radius(4px);
                }

                .badge{
                    width:auto !important;
                    padding:0.2rem 0.3rem !important;
                    text-align: center !important;
                    margin-right:3px;
                    height: auto;

                    font-size: 65% !important;
                    font-weight: 500 !important;
                    line-height:0.7 !important;
                    margin-top:-1px;


                }

                .badge-success{
                    color:$success  ;
                    border:1px solid $success ;
                    background-color:transparent ;

                    // text-transform: lowercase;
                }

                .badge-primary{
                    color:$primary  ;
                    border:1px solid $primary ;
                    background-color:transparent ;
                }

                .badge-info{
                    color:$info  ;
                    border:1px solid $info ;
                    background-color:transparent ;
                }

                .badge-warning{
                    color:$warning  ;
                    border:1px solid $warning ;
                    background-color:transparent ;
                }
                .badge-danger{
                    color:$danger  ;
                    border:1px solid $danger ;
                    background-color:transparent ;
                }

                .badge-secondary{
                    color:$secondary  ;
                    border:1px solid $secondary ;
                    background-color:transparent ;
                }



            }

            &:nth-child(3){
                font-size: 0.75rem;
                font-weight: 500;
                // padding:0 3px;
                font-weight: $gray-300;
                color:$gray-600;
            }

        }
    }

    .di-column{
        display: flex;
        flex-direction: row;
        // display: none;
        .data-image{
            margin-top:1px;
           width:36px;
           height: 36px;
           margin-right:10px;
           flex-shrink: 0;
           line-height: 30px;
           overflow:unset;
           @include border-radius(4px !important) ;
           position: relative;

           img{
                 width:100%;
               height: auto;
               @include border-radius(4px !important) ;
           }

           .image-status {
            position: absolute;
            width: 8px;
            height: 8px;
            top: -2px;
            right: -4px;
            z-index: 99;
            border: 1px solid $white;
            @include border-radius(50%);

            &.active {
              background-color: $success;
            }

            &.inactive {
              background-color: $danger;
            }
          }
        }

       .di-details{
           margin:0;
                li{
                   line-height: 20px;

                   &:nth-child(2){
                    font-size: 0.75rem;
                    font-weight: 500;
                   line-height: 20px;
                    font-weight: $gray-300;
                    color:$gray-600;
                   }
                }
       }
    }

   .doctor-column{
        margin-top:0.1rem;
        margin-bottom:0.3rem;

         li{
            line-height: 20px;
            font-weight: 500;
            padding:0;

            .badge{
                height: auto !important;
            }


            &:nth-child(2){
                font-size: 0.75rem;
                font-weight: 500;
                font-weight: $gray-300;
                color:$gray-600;
                span{

                    font-size: 0.7rem;
                    font-weight: 500;                    
                    color:$gray-600;

                }

            }
            .badge-success{
                color: #28a745;
                border: 1px solid #28a745;
                background-color: transparent;
            }
          

        }
    }
    .browser{
        img{
        width:11px;
        margin-right: 2px;
        height: auto;
        }
        }
    .fa{
        color:$gray-900;
    }
    .fa-money{
        color:$success !important;
    }

}

