@media (hover: hover),
(-ms-high-contrast: none) {

  .c-sidebar .c-sidebar-nav-link:hover,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #303644;
  }
}

@media (min-width: 768px) {

  .c-main>.container-fluid,
  .c-main>.container-sm,
  .c-main>.container-md,
  .c-main>.container-lg,
  .c-main>.container-xl,
  .c-main>.container-xxl {
    padding: 10px;
  }
}

@media (min-width: 768px) {

  .c-main>.container-fluid,
  .c-main>.container-sm,
  .c-main>.container-md,
  .c-main>.container-lg,
  .c-main>.container-xl,
  .c-main>.container-xxl {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.c-main {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 1;
  min-width: 0;
  padding-top: 0.2rem;
}

.c-subheader-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem;
  margin-bottom: 1.5rem;
  list-style: none;
  border-radius: 0;
  border-bottom: 1px solid;
  background-color: transparent;
  border-color: #d8dbe0;
}

.btn-dark {
  color: #fff;
  background-color: #303644;
  border-color: #303644;
}


.add-search {
  display: flex;
  gap: 0.5rem;
  margin-left: 0px;
  padding: 0;
}

.search-filter {
  display: flex;
  margin-top: 0px;
  margin-left: 2px;
  padding: 0
}

hr {
  margin-top: -10px;
  margin-bottom: 1rem;
  border: 0;
  /* border-top: 1px solid; */
  border-color: rgba(0, 0, 21, 0.2);
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  border-bottom: 1px solid;
  background-color: #fff;
  border-color: #d8dbe0;
}

.c-header .c-subheader {
  margin-top: -3px;
  border-top: 1px solid #d8dbe000;
}

.pl-3,
.px-3 {
  padding-left: 0rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0rem !important;
}

.c-header-with-subheader {
  border: none;
}

.c-header .c-subheader {
  margin-top: 0px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  marign-bottom: -5px
}

.nav-tabs {
  animation: none;
  border-bottom: 0px solid;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
  /* border: solid; */
  border-radius: 11px;

}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #223044;
  background-color: #EDF1F5;
  border-bottom: solid #223044;
}

.nav-tabs .nav-link {
  color: #223044;
  font-weight: 400;
  border: none;
  margin-right: 8px;
  border-bottom: #223044;
  border-bottom: solid gray;
  margin-bottom: 5px;

  &:hover {
    border-bottom: solid gray;
    color: #14253c;
    font-weight: 500;
  }

}

.form-control:focus {
  color: #768192;
  background-color: #fff;
  /* border-color: #04f096; */
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(43, 43, 84, 0.5);
}

.btn-primary {
  color: #fff;
  background-color: #303644;
  border-color: #303644;
}

.form-control:focus {
  color: #768192;
  background-color: #fff;
  border-color: #303644;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(43 43 84 / 10%);
}

.css-yk16xz-control:focus {
  color: #768192;
  background-color: #fff;
  border-color: #303644;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(43 43 84 / 10%);
}

.select-wrapper .select-label.active {
  color: #14253c
}

.field-wrapper.myInput input:not([disable])~.field-placeholder {
  color: #14253c;
  transform: scale(1) translateY(-18px) translateX(-6px);
  font-size: 0.6rem;
}

.btn-primary {
  color: #fff;
  background-color: #303644;
  border-color: #303644;
  border-radius: 5px;
  padding: 0.75rem 2rem;
  line-height: .6;

  &:hover {
    background-color: #384765;
  }
}

.c-sidebar {
  position: relative;
  display: flex;
  flex: 0 0 256px;
  flex-direction: column;
  order: -1;
  width: 221px;
  padding: 0;
  box-shadow: none;
}

@media (min-width: 992px) and (min-width: 992px) {

  html:not([dir=rtl]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper,
  html:not([dir=rtl]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed~.c-wrapper {
    margin-left: 220px;
  }
}

.c-header {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  min-height: 56px;
  height: 56px;
  background-color: #EDF1F5;
  border: none
}

html:not([dir=rtl]) .pagination {
  padding-left: 20px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #303644;
  border-color: #303644;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #303644;
  border-color: #303644;
  box-shadow: 0 0 0 0.2rem rgba(109, 112, 111, 0.5);
}

.c-wrapper:not(.c-wrapper-fluid) .c-header-fixed {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding-left: 10px;
}

.bg-white {
  background-color: #ffffff !important;
  border: none;
  border-radius: 3px;
}

.label-text {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #303644
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #626979;
  border-color: #626979;
}

.btn-outline-secondary {
  color: #4f5d73;
  background-color: #ced2d8;
  border-color: #ced2d8;

  &:hover {
    color: #fff;
    background-color: #707070;
    border-color: #ced2d8;
  }
}

.search-filter-item li:first-child button {
  padding-left: 0;
  padding: 10px 17px;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: #303644;
}

.field-wrapper.myInput input:not([disable])~.field-placeholder {
  // color: $cogent;
  color: #303644;
  -webkit-transform: scale(1) translateY(-20px) translateX(-6px);
  transform: scale(1) translateY(-20px) translateX(-6px);
  font-size: 0.65rem;
  font-weight: 500;

}

.ag-theme-balham .ag-header {
  background-color: #f5f7f7;
  color: #222c4a;
  font-weight: 600;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  border-bottom: 1px solid #BDC3C7;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #222c4a;
  border: none white;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid white;
  border-bottom-color: white;
  border: none;
}

.table tbody tr {
  line-height: 22px;

  &:hover {
    background-color: #f1f2f2;
  }
}

.table th,
.table td {
  padding: 10px;
  vertical-align: top;
  border-top: 1px solid;
  border-top-color: #d8dbe0;
}

.btn-group,
.btn-group-vertical {
  position: absolute;
  display: inline-flex;
  vertical-align: top;
  margin-top: -6px;
  margin-bottom: -6px;
}

.table-dropdown {

  .btn-check:checked+.btn,
  :not(.btn-check)+.btn:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    color: var(--bs-btn-active-color);
    background-color: red;
    border-color: red;
  }
    color: none;

    &:hover {
      color: #222c4a;
      background-color: none;
    }

    &:focus {
      color: none;
      box-shadow: none;
    }

    &:active {
      border: none;
      box-shadow: none;
      color: none;
      position: absolute
    }
  }