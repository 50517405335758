.add-container {
    padding: 15px;
    margin : 0rem 0;

    @include border-radius($cogent-radius);
    .title{
        font-weight: bolder;
        margin-left: 10px;
    }
    .title-button{
        display:flex;
        flex-direction: row;
        flex-wrap:nowrap;
        align-items: flex-end;
        justify-content: space-between;
    }
    .btn-outline-secondary {
        color: #012234;
        background-color: #ffffff;
        border-color: #ced2d8;
        padding: 4px 14px;
        line-height: 0.5;
        height:32px;
        border-radius: 5px;
        font-size: 14px;
    }
}

.add-info {
    .form-check-input[type="radio"] {
        // margin-top: 0.1rem;
    }
    btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
             color: #fff;
             background-color: #012141;
             border-color: #011334;
         }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgb(40 136 99 / 40%);
    }
    .btn-outline-primary {
        color: #011334;
        border-color: #012141;
    }
    .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
        color: #fff;
        background-color: #012141;
        border-color: #012234;
    }
    .btn-outline-primary:hover {
        color: #fff;
        background-color: #012234;
        border-color: #012234;
    }

}

// .radio-container{
//     .form-label {
//     display: block;
// }

// }



.form-check{
    display: inline-block;
    margin-right:10px;
   
}

.form-check-input[type="radio"] {
    margin-top: 0.1rem;
}


.assign-menu {
    border       : 1px solid $gray-200;
    padding      : 5px 0 0;
    border-radius: 5px;
    height       : 360px;
    //background-color:$white;

    .am-header {
        border-bottom : 1px solid $gray-200;
        padding       : 0 10px 4px;
        display       : flex;
        flex-direction: row;
        position      : relative;

        .searchMenu {
            position: absolute;
            right   : 50px;
            top     : -5px;
            width   : calc(100% - 50px);

            #expandible-search input[type=search] {
                border: none;
            }

        }

        .select-all {
            position: absolute;
            right   : 0;
            width   : 40px;
            margin-top:0;
            margin-right:0;
            ::-webkit-input-placeholder {
        /* WebKit browsers */
         color: transparent;
    }
     :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
         color: transparent;
    }
     ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
         color: transparent;
    }
     :-ms-input-placeholder {
        /* Internet Explorer 10+ */
         color: transparent;
    }
     input::placeholder {
         color: transparent;
    }

            &.form-check .form-check-label:before {
                margin-left: -4px;
            }
        }

    }

    .am-title {
        font-size  : 14px;
        line-height: 20px;
        font-weight: 400;
    }


    .card {
        border-radius: 0;
        border       : none;
        // background-color:$white;
        &>:not(:first-child) {
            div{
            padding-left: 44px;
            font-size   : 0.75rem;
            }


        }

        & .collapse{
            background-color: lighten($cogent,72%);

            .card-header{
                background-color:rgba($cogent,0.01);
                transition: 0.3s;
                &:hover {
                    background-color: lighten($cogent, 65%);
                      cursor            : pointer;

                  }
            }

       }


    }



    .card-header:first-child {
        border-radius: 0;
    }

    .card-header {
         background-color: $white;
        border-bottom   : 0px solid lighten($cogent-border, 5%);
        display         : flex;
        flex-direction  : row;
        justify-content : flex-start;
        align-items     : center;
        padding         : 0.75rem 0.6rem 0.75rem 0.9rem;
        // transition: 0.3s;

        & :nth-child(1) {
            flex: 0 0 30px;
        }

        & :nth-child(2) {
            flex: 1 0 120px;
        }

        & :nth-child(3) {
            flex: 0 0 20px;
            margin-top:-5px;
        }

        &.activeParent {
            background-color: lighten($cogent,10%) !important;
            color           : $white !important;

        }

        &:hover {
            //background-color:lighten($cogent, 20%);
            //color             : lighten($gray-900, 0%);
            cursor            : pointer;
            background-color: lighten($cogent, 72%);

        }





    }



    // .card .show {
    //     border-left: 10px;

    // }

    .card .activeChild .card-header {
        // background-color: lighten($cogent, 10%);
        // color           : $white;
        position           : relative;
        background-color   :lighten($cogent, 42%);
        color:$cogent;

        &:before {
            content    : "";
            position   : absolute;
            top        : 0;
            bottom     : 0;
            left       : 0;
            border-left: 3px solid lighten($cogent,10%);

        }
    }

    .card-header.activeChild{
        position           : relative;
        background-color   :lighten($cogent, 65%);
        color:$cogent;

        &:before {
            content    : "";
            position   : absolute;
            top        : 0;
            bottom     : 0;
            left       : 0;
            border-left: 3px solid lighten($cogent,10%);

        }
    }
}

.previledge-title {
    display        : flex;
    flex-direction : row-reverse;
    justify-content: space-between;
    align-items    : flex-start;
    //margin-bottom:10px;
    // h5,button{
    //     display: inline-block;
    // }

    // button{
    //     margin-top:-8px;
    // }

}

.assign-previledge {
    border       : 1px solid $gray-200;
    padding      : 5px 0;
    border-radius: 5px;
    height       : 360px;
    // padding   :20px 20px;

    .am-title {
        font-size  : 14px;
        line-height: 20px;
        font-weight: 400;
    }

    .am-header {
        border-bottom : 1px solid $gray-200;
        padding       : 0 10px 4px;
        display       : flex;
        flex-direction: row;
        position      : relative;

        .searchMenu {
            position: absolute;
            right   : 50px;
            top     : -5px;
            width   : calc(100% - 50px);

            #expandible-search input[type=search] {
                border: none;




            }

        }

        .select-all {
            position: absolute;
            right   : 0;
            width   : 40px;
            margin-right:0;

            &.form-check .form-check-label:before {
                margin-left: -4px;
            }
        }

    }

    .assign-body {
        padding: 20px;
    }


    .assign-item {
        padding      : 10px;
        margin-bottom: 10px;

        .form-check {
            margin-bottom: 5px;
        }

        .row {
            margin-left: 15px;
        }
    }

    .assign-header {
        font-weight: 700;

        .form-check .form-check-label {
            font-weight: 400;
            font-size  : 0.9rem;
        }
    }

}

.view-assigned-menu {
    padding: 5px 0;

    .role-header {
        text-transform: uppercase;

    }

    .menu-role>div {
        margin-bottom: 5px;

        i {
            color: $gray-700;
        }
    }
}



.manage-details {
    background-color: $white;
    padding-top: 15px;
    margin          : 1rem 0;
    @include border-radius($cogent-radius);

    h2 {
        padding-left: 10px;
    }
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        margin-bottom: 1.5rem;
        word-wrap: break-word;
        background-clip: border-box;
        border: 0px solid;
        border-radius: 0.25rem;
        background-color: #fff;
        border-color: #d8dbe0;
        height: 100%;
    }
}

.profile-edit-modal{
    .assign-menu, .assign-previledge{
        height:422px;
    }


}


.preview-modal {
    .modal-content {
        border        : none;
        padding-bottom: 30px;

        .modal-header {

            // background-color         : darken($cogent, 5%);
            // background      : linear-gradient(180deg,darken($cogent, 25%), rgba(0,212,255,1) 100%);
            //background-image : url("../images/clinic5.png");
            justify-content    : center;
            background-repeat  : no-repeat;
            background-size    : cover;
            background-position: center;
            position           : relative;
            padding       : 25px;
            padding-bottom:0px;
            // margin-bottom      : 20px;
            text-align         : center;
            color              : $gray-800;
            border             : none;
            opacity            : 0.9;

            .close{
                color:$gray-800;
                font-size: 1.6rem;

                &:hover{
                    color:$gray-900;

                }
            }



            .modal-title{
                // text-decoration: underline;
                // position: relative;
                // &::before{
                //     content: "";
                // display: block;
                // position: absolute;
                // width: 25%;
                // height: 1px;
                // bottom: -2px;
                // background-color: #6c757d;
                // }
            }

        }

        .modal-body{
            padding:25px;
        }

        .modal-footer{
            padding:0 25px;
        }
    }

    #label_status {
        display: block;
    }

    .status-box{
        margin-top:-10px;
        margin-bottom:16px;
    }

    .form-check {
        display      : inline-block;
        padding-right: 10px;
    }

    .assign-item{
        .assign-header{
            i{
                font-weight: 700;
                color:$gray-700;
            }
        }
        i{
            font-weight: 400;
            color:$gray-600;
            font-size: 12px;
        }
    }

    .assign-previledge{
        #scrollbar_menus{
            height: calc( 100% - 25px);
        }
    }
}

.dash-roles-container{
    .module{
        display: block;
        margin-bottom:6px;
        text-transform: capitalize;
    }
}

.doctor-with-image{
    display: flex;
    flex-direction: row;
    align-items: center;

    img{
        width:26px;
        height: auto;
        margin-right:10px;
        @include border-radius(50%);
    }

    .doctor-name{

    }

    .anchor-icon{
        margin-right: 10px;
        width:28px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        flex-shrink: 0;
        @include border-radius(50%);
        border:1px solid $gray-100;
        background-color:$gray-200;
    }

}

.multiple-select{
    // min-height: 54px;
    margin-bottom:20px;
}

.d-flex{
    .react-datepicker-wrapper{
        width:50%;
    }
}

.fa-money{
    color:$success;
}


@include media-breakpoint-down(md) {
    .add-info {
        margin-bottom: 30px;
        .field-wrapper {
            position: relative;
            min-height: 50px;
        }
    }

}

@include media-breakpoint-down(sm) {

    .add-info,
    .menu-list-wrapper,
    .roles-wrapper {
        margin-bottom: 30px;
    }

}
