h5.title{

    text-transform: uppercase;
    color:darken($gray-900,0%);
    margin-bottom:14px;
    font-size:1rem;
    position: relative;
    display: inline-block;
    letter-spacing: 0.04rem;
    text-transform: capitalize;
    //  padding-left:8px;
    // margin-left:-20px;

     &:before{
    //     content:"";
    //     position: absolute;
    //     display: inline-block;
    //     bottom:-5px;
    //     top:0px;
    //     bottom:10px;
    //     left:0;
    //     width:20px;
    //     height: 2px;
    //     background-color:$cogent;
    //     // border-left:2px solid lighten($body-color,0%);
    // border-bottom-left-radius: 2px;
    // border-bottom-right-radius: 2px;
    //     border-bottom:2px solid lighten($body-color,0%);

    }
}
