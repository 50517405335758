// .select-label.active{
   
// }

.css-1pahdxg-control{
    box-shadow:none !important;
    border-color:$cogent !important;
    &:hover{
        border-color:$cogent !important;
    }
}

