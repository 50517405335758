
  .advanced-search {
    padding: 10px;
    background-color: #fff;
    margin-bottom: 1rem;
    position: relative;
    transition: max-height 0.3s ease-out;
    -webkit-transition: max-height 0.3s ease-out;
    height: auto;
    border-radius: 3px;
}