.primary {
    background-color: $cogent;
}

.unauthorized{
    @include border-radius(1rem);
    margin-bottom:5px;
    .filter-message{
    @include border-radius(0.5rem);
    }
}

.search-filter-item {
    margin:0;
    
    li {
        display: inline-block;
        padding: 0px;
        padding-right:8px;

        // &:first-child{
        //     padding-left:0;
        //    button{
        //     padding: 9px 20px !important;
        //    } 
        // }

        button {
            padding: 10px 10px;

        }

        &:first-child {
            button {
                padding-left:0;
                padding: 0;
            }
        }
    }
}


.search-filter-wrapper{
    margin:0.2rem 0;
    .search-filter-item li:first-child button {
        padding-left: 0;
        padding: 4px 14px;
    }
    .btn-secondary {
        background-color: $white;
        border-color: $gray-200;
        color: $gray-800;

        &:hover {
            background-color: $gray-100;
            border-color: $gray-500;
            color: $gray-800;
        }
    }
        .btn-primary{
            background-color:$white;
            border-color:$gray-200;
            color:$gray-800;

            &:hover{
                background-color:$gray-100;
                border-color:$gray-500;
                color:$gray-800;
            }
            &:focus{
                box-shadow: none;
                background: #ffffff;
            }

    }

    
}

// .search-filter-wrapper{
//     margin:0px -5px 0px;
// }

.search-toggle-btn{
    position: absolute;
    display: inline-block;
    bottom:0;
    left:50%;
    transform: translateX(-50%);

    .btn{
    width:60px;
    padding:6px 0;
    i{
        font-size: 20px;
    }
    }
}

.advanced-search{
    // border-bottom:1px solid $cogent-border;
    padding:15px;
    background-color:$white;
    margin-bottom:1rem;
    position: relative;
    // overflow:hidden;
    transition:max-height 0.3s ease-out; 
    -webkit-transition: max-height 0.3s ease-out; 
    height:auto;
    // max-height:600px; 
    @include border-radius($cogent-radius);

    &.collapsed{
        max-height: 0;
        padding:0;
        display:none;
    }
    .btn-primary {
        color: #fff;
        background-color: #303644;
        border-color: #303644;
        border-radius: 5px;
        padding: 4px 10px;
        line-height: 0.6;
    }
    .title {
        color: black;
        padding-top: 0px;
    }
    h5.title, .title.h5 {
        text-transform: uppercase;
        color: #212529;
        margin-bottom: 15px;
        font-size: 1rem;
        position: relative;
        display: inline-block;
        letter-spacing: 0.04rem;
        text-transform: capitalize;
    }
    btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #012141;
        border-color: #011334;
    }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
        box-shadow: 0 0 0 0.2rem rgb(40 136 99 / 40%);
    }


}

.advanced-search:hover{
  height:auto;
   
    
}