// Brand Colors
$cogent-accent: #4bd6f2 !default;
//  $cogent:         #2e323e !default;
// $cogent:      #0d5dae !default;
$cogent: #222c4a !default;
$facebook: #3b5998 !default;
$twitter: #00aced !default;
$linkedin: #4875b4 !default;
$google-plus: #d34836 !default;
$flickr: #ff0084 !default;
$tumblr: #32506d !default;
$xing: #026466 !default;
$github: #4183c4 !default;
$html5: #e34f26 !default;
$openid: #f78c40 !default;
$stack-overflow: #fe7a15 !default;
$youtube: #b00 !default;
$css3: #0170ba !default;
$dribbble: #ea4c89 !default;
$google-plus: #bb4b39 !default;
$instagram: #517fa4 !default;
$pinterest: #cb2027 !default;
$vk: #45668e !default;
$yahoo: #400191 !default;
$behance: #1769ff !default;
$dropbox: #007ee5 !default;
$reddit: #ff4500 !default;
$spotify: #1db954 !default;
$vine: #00bf8f !default;
$foursquare: #1073af !default;
$vimeo: #aad450 !default;
$brown: #966f33 !default;
$purple: #800080;
$esewa: #5fb946;
$fonehealth: #46c9bd;
$fonepay: #cf2028;

$brands-colors: () !default;
$brands-colors: map-merge(
                (
                        "cogent-red": $vimeo,
                        "cogent": $cogent,
                        "cogent-accent": $cogent-accent,
                        "facebook": $facebook,
                        "twitter": $twitter,
                        "linkedin": $linkedin,
                        "google-plus": $google-plus,
                        "flickr": $flickr,
                        "tumblr": $tumblr,
                        "xing": $xing,
                        "github": $github,
                        "html5": $html5,
                        "openid": $openid,
                        "stack-overflow": $stack-overflow,
                        "youtube": $youtube,
                        "css3": $css3,
                        "dribbble": $dribbble,
                        "instagram": $instagram,
                        "pinterest": $pinterest,
                        "vk": $vk,
                        "yahoo": $yahoo,
                        "behance": $behance,
                        "dropbox": $dropbox,
                        "reddit": $reddit,
                        "spotify": $spotify,
                        "vine": $vine,
                        "foursquare": $foursquare,
                        "vimeo": $vimeo,
                        "esewa": $esewa,
                        "fonehealth": $fonehealth,
                        "fonepay": $fonepay,
                ),
                $brands-colors
);

$cogent-padding: 2rem;
$cogent-radius: 0.5rem;
$cogent-bg: #f0f2fc;
