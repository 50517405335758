.restriction-box{
    position: relative;

    .form-label{
        position: absolute;
        padding:0;
        margin-top:-15px;
        font-size: 0.8rem;
    }

    .switch{
        line-height: 28px;
        max-height: 28px;
        min-height: 28px;  
        margin-top:6px;   
        border:none;

        &.btn{
            min-height: 28px !important;
        }
    }

   
    .switch-group{
        line-height: 28px;
        max-height: 28px;
        

        .switch-on,.switch-off{
            line-height: 28px;
           padding:0;
           max-height: 28px;
           border:none;
           
        }

        .switch-handle{
            line-height: 24px;
            max-height: 25px;  
            padding:10px 13px;
            margin-top:-1px;
        }
    
        .switch-off{
            background-color: $danger;
            color:$white;
            border:none;
            // border:1px solid #ccc;
        }
    
    }

}

