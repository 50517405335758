.filter-message {
    padding : 10px 15px 30px;
    display:flex;
    flex-direction: column;
    align-items: center;
    // position: relative;

    .no-data {
        position        : relative;
        background-color: $danger;
        width           : 100px;
        height          : 100px;
        // top             : 50%;
        // left            : 50%;
        @include border-radius(50%);
        // transform: translate(-50%, -50%);

        i {
            position : absolute;
            top      : 50%;
            left     : 50%;
            transform: translate(-50%, -50%);
            color    : $white;
            font-size: 40px;

        }
    }

    .message {
        // position : absolute;
        // top      : 80%;
        // left     : 50%;
        // transform: translateX(-50%);
        margin-top:10px;
        font-size:14px;
       color:$gray-900;
       text-align: center;
    //    font-style: italic;
    }

    .message-content {
        // position : absolute;
        width    : 100px;
        height   : 100px;
        margin-top      : 50px;
        // left     : 50%;
        // transform: translate(-50%, -50%);
    }

    .loader {
        width : 60px;
        height: auto;
    }

    .span {
        display: block;
    }
}

.error-message {
    color: $danger;
}

.add-container,.menu-list-wrapper ,.assign-previledge{
    .filter-message {
        padding: 100px 15px;


        .no-data {

            background-color: $warning;
            width           : 60px;
            height          : 60px;

            i {


                font-size: 30px;

            }
        }

        .message{
            top:70%;
        }
    }
}

.c-loading{

    .message-content{
        width:160px;
        text-align: center;
    }
    .loader{
        width:120px;
    }
    span{
        display: block;
        margin-top:14px;
        font-size:16px;
        // text-transform: uppercase;
    }
}

.overlay-loading{
    padding:40px 50px;
   
    .message-content{
        width:100%;
        text-align: center;
    }
    .loader{
        width:100px;
    }

    .process-message{
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
    }

    span{
        display: block;
        margin-top:14px;
        font-size:16px;
        // text-transform: uppercase;
    }
}
