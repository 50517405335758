.field-wrapper {
  position: relative;
  min-height: 68px;
}

.field-wrapper input {
  border: 1px solid $cogent-border;
  // padding: 15px;
  border-radius: 15px;

  &:hover{
    border:1px solid $gray-500;
    
  }
  &:focus{
    border:1px solid $cogent;
  }
}

.field-wrapper .field-placeholder {
  font-size: 0.8rem;
  line-height:1rem;
  position: absolute;
  top: 11px !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color:$gray-600;
  font-weight: 300;
  left: 8px;
  padding: 0 8px;
  -webkit-transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
  text-align: left;
  @include border-radius(4px);
 
}

.field-wrapper .field-placeholder span {
  background: $white;
  padding: 0px 5px;
}

.field-wrapper.myInput input:not([disable]) ~ .field-placeholder {
  // color: $cogent;
  color:black;
  -webkit-transform: scale(1) translateY(-20px) translateX(-6px);
  transform: scale(1) translateY(-20px) translateX(-6px);
  font-size: 0.65rem;
  font-weight: 50
  
}

.field-wrapper.errorInput input:not([disable]) ~ .field-placeholder {
  color: $red;
}

.field-wrapper.hasValue input:not([disabled]) ~ .field-placeholder {
  -webkit-transform: scale(1) translateY(-20px) translateX(-6px);
  transform: scale(1) translateY(-20px) translateX(-6px);
  font-size: 0.65rem;
 font-weight: 400;
}

.field-wrapper .err-message {
  display: inline-block;
  margin-top:0;
}

//for text area


.form-control:disabled{
  background-color:$gray-100;
  // &:hover{
  //   cursor: not-allowed; 
  // }
}

.field-wrapper .show-btn-pwd{
  border-color:$gray-300;
   border:none;
  //  &:hover{
  //    background-color:none;
  //    color:inherit;
  //  }
}





