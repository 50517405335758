.xy-center{
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);

}

.x-center{
    left:50%;
    transform: translateX(-50%);
}

.y-center{
    top:50%;
    transform: translateY(-50%);
}