.dashboard-wrapper {
    //padding:16px;
    padding-top: 10px;
    margin-bottom: 1.5rem;

    h5.title {
        display: block;
    }

    .react-datepicker-popper[data-placement^="bottom"] {
        margin-top: -8px !important;
    }

    .fiscal {
        font-size: 11px;
        font-weight: 500;
        display: block;
    }

    .slash {
        font-size: 8px;
    }

    .rev-total {
        background-color: $gray-100;
        color: $gray-900;
        padding: 3px 10px;
        margin-top: 10px;
        margin-left: 10px;
        @include border-radius(4px);
    }

    .hospital-list {
        width: 240px;
        float: right;

        .hospital-list-input {
            position: relative;

            .form-label {
                position: absolute;
                left: 7px;
                bottom: 26px;
                padding-bottom: 2px;
                background-color: transparent;
                margin-bottom: 2px;
                font-size: 11px;
                z-index: 99;
            }

            .select-wrapper {
                min-height: 38px;
                margin-left: 4px;

                .select-label.active {
                    color: $cogent;
                }

                .css-1hwfws3 {
                    padding: 0;
                }

                .css-yk16xz-control {
                    background-color: transparent;
                    border-color: transparent !important;
                    border-bottom: 1px solid $gray-500 !important;
                    //    min-height: 0px !important;

                    @include border-radius(0);

                    &:hover {
                        // border:transparent !important;
                        border-bottom: 1px solid $gray-500 !important;
                    }
                }
            }

            .css-g1d714-ValueContainer {
                padding: 0;
            }

            .css-1pahdxg-control {
                border-color: transparent !important;
                border-bottom: 1px solid $gray-500 !important;
                background-color: transparent;
                @include border-radius(0);
                &:hover {
                    border-color: transparent !important;
                    border-bottom: 1px solid $gray-500 !important;
                }
            }
        }
    }

    .react-datepicker-wrapper {
        width: 140px;
    }

    .react-datepicker__current-month {
        display: inline-block !important;
    }

    .unauthorized {
        // position: relative;
        padding: 0;
        @include border-radius(1rem);

        .filter-message {
            position: relative;
            width: 100%;
            height: auto;
            margin-top: 0px;
            left: 50%;
            transform: translateX(-50%);
            @include border-radius(0.5rem);
            // border-radius:50%;
            background-color: $white;
            // border:1px solid #ccc;
            h1 {
                margin-bottom: 40px;
                &:after {
                    content: "";
                    position: absolute;
                    width: 100px;
                    margin-top: 50px;
                    border-bottom: 4px solid gray;
                    left: 50%;
                    transform: translateX(-50%);
                    text-transform: uppercase;
                }
            }

            h5 {
                text-transform: uppercase;
            }

            h6 {
                color: rgba(89, 93, 110, 0.9);
                padding: 10px 0;
                font-size: 0.9rem;
            }
        }
        .message-cont {
            text-align: center;
            width: auto !important;

            width: auto;
        }

        i {
            font-size: 60px;
            color: #dc3545;
        }
    }
}

.dashboard-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .revenue-box {
        position: relative;
        background-color: $white;
        padding: 52px 0;
        margin: 0px 0 15px;
        flex-grow: 1;
        flex-basis: 0;
        justify-content: space-between;
        @include border-radius(0.5rem);
        text-align: center;
        .rdb-container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: 0.5s ease;
            background-color: rgba($white, 1);
            -webkit-transition: 0.5 ease-in-out;
            transition: 0.5s ease-in-out;
            @include border-radius(0.5rem);

            display: flex;
            flex-direction: row;
            align-content: space-between;
        }

        &:hover {
            .rdb-container {
                opacity: 1;
            }

            cursor: pointer;
        }

        .rdb-total {
            padding-left: 10px;
            padding-right: 10px;
            align-self: center;
            flex: 1 2 100px;
            text-align: center;
            font-size: 22px;
            font-weight: 500;

            span {
                margin-left: 3px;
                font-size: 10px;
                font-weight: 400;
            }
        }

        .revenue-details-box {
            flex: 2 0 130px;

            width: 100%;
            padding-left: 10px;

            display: flex;
            flex-direction: column;

            // clear: both;

            .rd-row {
                // float:left;
                display: flex;
                flex-direction: row;
                // border:1px solid green;
                align-items: center;
                height: 40px;
                margin: 0;
            }

            .badge {
                padding: 0;
                line-height: 22px;
                // width:6px;
                // height:6px;
            }

            .rd-col {
                flex-direction: column;
                // border:1px solid green;

                p {
                    font-size: 10px;
                    line-height: 12px;
                    margin: 0;
                    letter-spacing: 0;
                    color: $gray-600;
                    text-align: left;
                }

                .amount,
                .appt {
                    color: $gray-900;
                    font-size: 11px;
                    margin-left: 0;
                }
            }

            .rd-col-1 {
                width: 2px;
                margin-right: 4px;
            }

            .rd-col-2 {
                // width:160px;

                p:first-child {
                    font-size: 10px;
                    font-weight: 400;
                    text-transform: uppercase;
                }
                p {
                    align-self: center;
                }
            }
        }

        .tb-data {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: 0.5s ease;

            background-color: $white;
            -webkit-transition: 0.5 ease-in-out;
            transition: 0.5s ease-in-out;
            @include border-radius(8px);

            span {
                color: transparent;
                font-size: 13px;
                line-height: 15px;
                display: none;
                margin: 1px 0;
                .amt {
                    padding: 2px 4px;
                    //    border:1px solid green;
                    @include border-radius(2px);
                    color: green !important;
                }
            }
        }

        &:hover {
            .tb-data {
                padding: 30px;
                opacity: 1;

                span {
                    text-align: left;
                    display: inline-block;
                    color: rgba($gray-900, 1);
                }
            }
        }

        &:first-child {
            background-color: $cogent;
            box-shadow: -10px -10px 8px #fff, 10px 10px 10px $gray-400;
            // .tb-data span{
            //     color:rgba(255, 255, 255, 0.8);
            //     font-size: 12px;
            //     line-height: 10px;
            // }

            .rdb-container {
                background-color: $gray-100;
            }

            p {
                color: $white;
            }
            .total {
                color: $black;

                .up {
                    color: rgba(255, 255, 255, 0.7);
                }

                .down {
                    color: $danger;
                }
            }
        }

        &:nth-child(2) {
            margin: 15px;
            margin-top: 0;
        }

        &:nth-child(3) {
            margin: 0px 15px 15px 0;
        }

        .total {
            font-size: 14px;
            font-weight: 400;
            color: $cogent;
        }

        img {
            width: 30px;

            // display: none;
        }

        p {
            text-align: center;
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 0;

            span {
                margin-left: 3px;
                font-size: 10px;
                font-weight: 400;
            }
        }

        div {
            text-align: center;
            font-weight: 400;
            // color:$black;

            & .up {
                color: $success;
                font-weight: 500;

                svg {
                    margin-right: 4px;
                }
            }

            & .down {
                color: $danger;
                font-weight: 500;
                svg {
                    margin-right: 4px;
                }
            }

            &.up,
            &.down {
                span {
                    // color:$gray-600;
                    // margin-left:2px;
                    // font-weight: 500;
                }
            }
            .fa-chevron-up {
                color: $success;
                font-size: 18px;
            }

            .fa-chevron-down {
                color: $danger;
                font-size: 18px;
            }
        }
    }

    .line-chart {
        background-color: $white;
        padding: 20px;
        width: 100%;
        height: calc(100% - 20px);
        @include border-radius(0.5rem);
        // box-shadow:   -10px -10px 10px $gray-200,
        // 10px 10px 10px $gray-400;

        .chartjs-render-monitor {
            padding: 0 10px;
        }

        img {
            width: 100%;
            height: auto;
        }

        .btn {
            // margin-right:6px;
            margin-bottom: 4px;
            float: right;
        }
    }

    .doctor-revenue {
        height: 100%;
        // min-height: 300px;

        .dr-number,
        .dr-amount {
            position: relative;

            .dr-details {
                display: none;
                position: absolute;
                top: 0;
                li {
                    $color: $gray-700;
                }

                .fa-money {
                    color: $success !important;

                    &.red {
                        color: $danger !important;
                    }
                }

                .fa {
                    margin-right: 3px;
                }

                .fa-check-circle-o {
                    color: $success;
                }

                .fa-close {
                    color: $danger;
                }
            }

            &:hover {
                color: transparent;
                .dr-details {
                    display: block;
                    color: $gray-700 !important;
                    li {
                        color: $gray-700 !important;
                    }
                }
            }
        }

        .app-log {
            background-color: $white;
            padding: 20px 30px;
            width: 100%;
            height: calc(100% - 20px);
            // box-shadow:   -10px -10px 10px $gray-200,
            // 10px 10px 10px $gray-400;
            @include border-radius(0.5rem);

            .app-queue-datatable {
                .filter-message {
                    // margin-top:130px;
                    position: absolute;
                    top: 60%;
                    left: 50%;
                    width: 100%;
                    height: 200px;
                    transform: translate(-50%, -60%);
                }
            }

            .filter-message {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100%;
                height: 200px;
                transform: translate(-50%, -50%);
            }
        }

        .total-left {
            div {
                width: 100%;
                margin-bottom: 2px;

                .label {
                    width: 130px;
                    display: inline-block;
                }
            }
        }

        .t-app-box,
        .t-amount-box {
            padding: 10px;
            border: 1px solid $gray-200;
            @include border-radius(4px);
        }

        .t-amount-box {
            text-align: right;
        }

        .react-datepicker-popper[data-placement^="bottom"] {
            margin-top: -8px !important;
        }

        .react-datepicker-popper {
            margin-left: -100px;
        }

        .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
        .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before {
            margin-left: 100px;
        }
    }

    .appointment-queue {
        height: 550px;
        // margin-left:-15px;
        //     margin-right:-15px;

        .app-log {
            background-color: $white;
            padding: 20px 30px;
            width: 100%;
            min-height: 500px;
            // box-shadow:   -10px -10px 10px $gray-200,
            // 10px 10px 10px $gray-400;
            @include border-radius(0.5rem);

            .filter-message {
                position: absolute;
                left: 50%;
                top: 30%;
                width: 100%;
                height: 200px;
                transform: translate(-50%, -30%);
            }
        }
    }

    .app-log {
    }

    .overall-box {
        background-color: $white;
        padding: 30px;
        margin: 0px 0 15px;
        @include border-radius(0.5rem);
        text-align: center;
        // box-shadow:   -10px -10px 10px $gray-200,
        // 10px 10px 10px $gray-400;

        img {
            width: 30px;

            // display: none;
        }

        p {
            text-align: center;
            font-size: 22px;
            font-weight: 500;
        }

        .title {
            text-align: center;
            font-weight: 500;
            font-size: 14px;
            // color:$black;
        }
    }

    .appointment-box {
        background-color: $white;
        padding: 30px;
        padding-bottom: 10px;
        margin: 0px 0 15px;
        text-align: center;
        // box-shadow:   -10px -10px 10px $gray-200,
        // 10px 10px 10px $gray-400;
        @include border-radius(0.5rem);

        .date-group {
            text-align: left;
            padding-left: 0px;
        }

        .date {
            padding-right: 0px;
        }

        .doughnut-chart {
            width: 60%;
            text-align: center;
            margin: 0 auto;
            position: relative;

            .mid-data {
                position: absolute;
                width: 100%;
                height: 40px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                line-height: 18px;
                z-index: 999999999999999;
                font-weight: 500;
                font-size: 0.9rem;
            }
        }

        // .title{
        //     font-size: 14px;
        //     font-weight: 500;
        //     margin:0;
        // }

        // p{
        //     font-size: 22px;
        //     font-weight: 500;
        //     margin:0;
        // }
        .total-count {
            margin-top: 10px;
            text-align: center;
            font-weight: 500;
            font-size: 0.9rem;
        }

        .legend-box {
            display: block;
            //    min-width: 320px;
            width: 320px;
            height: auto;
            border: 1px solid #ccc;
            margin: 20px auto;
            padding: 12px;
            @include border-radius(0.5rem);

            ul li {
                width: 100%;
                float: left;
                height: 26px;
                display: flex;
                flex-direction: row;
                justify-content: left;
                text-align: center;
                // align-items: center;

                // span{
                //     // border:1px solid #ccc;
                //     width:8px;
                //     height: 6px;
                // }

                .legend {
                    width: 15px;
                    height: 15px;
                    display: inline-block;
                    padding: 0.55em 0.55em;
                    margin-right: 6px;
                    background-color: $primary;
                    line-height: 1;
                    // text-align: center;
                    border-radius: 0.25rem;
                }

                .data {
                    font-weight: 500;
                    font-size: 12px;
                    color: $gray-900;
                }

                &:nth-child(1) {
                    .legend {
                        background-color: rgba(0, 99, 255, 0.2);
                    }
                }

                &:nth-child(2) {
                    .legend {
                        background-color: $cogent;
                    }
                }

                &:nth-child(3) {
                    .legend {
                        background-color: rgba(0, 99, 255, 0.6);
                    }
                }
            }

            p {
                text-align: center;
                font-weight: 500;
                font-size: 0.9rem;
            }
        }
    }
    .appt-mode {
        .doughnut-chart {
            .mid-data {
                top: 85%;
            }
        }
        .legend-box {
            align-items: center;
            // width: 350px;
            padding: 18px 10px;
            padding-bottom: 0;
            ul {
                display: flex;
                &:nth-child(1) {
                    li {
                        &:nth-child(1) {
                            .legend {
                                background-color: $esewa;
                            }
                        }

                        &:nth-child(2) {
                            .legend {
                                background-color: $fonehealth;
                            }
                        }
                    }
                }
                &:nth-child(2) {
                    li {
                        &:nth-child(1) {
                            .legend {
                                background-color: #f89020;
                            }
                        }

                        &:nth-child(2) {
                            .legend {
                                background-color: #c8ced2;
                            }
                        }
                    }
                }
            }
        }
    }

    .separator-doc,
    .separator-date {
        font-size: 14px;
        font-weight: 500;
    }

    .separator-doc {
        margin-top: 10px;
    }

    .date {
        padding: 0;
        text-align: right;

        .field-wrapper {
            min-height: 40px;
        }

        & > div {
            text-align: right;
            font-size: 10px;

            span {
                font-weight: 600;
            }
        }

        .form-control {
            border: 0 solid transparent;
            border-bottom: 1px solid $gray-500;
            @include border-radius(0);
        }

        .field-placeholder {
            display: none;
        }

        .example-custom-input {
            text-align: 0;
        }
    }

    .app-queue-datatable {
        width: 100%;

        .data-image {
            //   width:25px;
            margin-top: 4px;
        }

        ul li {
            line-height: 22px;
        }
    }
}

.revenue-header {
    text-align: center;
}

@include media-breakpoint-down(sm) {
    .doctor-revenue {
        margin-top: 1.5rem;
    }
}

// .dashboard-image{
//     width:100%;
//     max-width:800px;
// }
