.dark-mode {
  // filter:invert(100%)  hue-rotate(250deg);
  // filter: contrast(10);
 filter: invert(95%);
  img{
    filter:invert(95%);
  }

  #wrapper #sidebar-wrapper{
    position:fixed;
  }
}

.switch-container {
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 padding:0px;
 align-items: center;

  // transform: translateY(-50%);
  z-index: 999;
  // background-color:$gray-100;
  @include border-radius(8px);
  // border:1px solid $primary;

  span{
    i{
      margin-right:10px;
    }
  }

  p{
    // color:$primary;
     display: inline;
     align-self: center;
     margin:0;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 27px;
    margin-bottom:0;
    right:0;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
.nav{
  background-color: none;
}