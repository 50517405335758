.editable-table-container{
  .add-new{
    width:80px;
    margin:0 0 20px;
    padding:7px 0;
    float:right;
 
    
}

.editable-table{
   border-bottom:1px solid $cogent-border;
  
      .table-header{
          margin-bottom:0;
          border:none;
          td{
              font-weight: 500;
              text-transform: capitalize;
              font-size: 12px;
              letter-spacing: 0.02rem;
            
              border: none;
              border-bottom: 1px solid $gray-200;
              border-left: 1px solid $gray-200;
              height: 50px;
  
              &:nth-child(1){
                  border-left: 1px solid transparent;
              }
          }
        
      }
  
      .table-body{     
        border-top:0;
        height: 40px;
        border-width: 1px;
        border-color: $cogent-border;
        

        tr{
            &:hover,&:focus,&.activeRow{
                height: 40px;
                border-width: 1px;
                background-color: lighten($cogent, 70%);
                border-color: $cogent-border;

                .table-action{
                    display: block;

                    .action-box{
                      display: inline-block;
                      position: relative;
                      .tip{
                        position: absolute;
                        width:auto;
                        height: auto;
                        display:inline-block;
                        background-color: $gray-800;
                        left:-42px;
                        top:-10px;
                        text-align: center;
                        color:$white;
                        @include border-radius(2px);
                        margin-right:20px;
                        opacity: 0;
                        font-size: 12px;
                        padding:4px;
                        font-weight: 300;
                        transition:1s ease-in;
                        display:none;

                      }

                      &:hover{
                        .tip{
                          opacity: 1;
                          z-index: 99999;
                        }
                      }


                    }



                    .btn-outline-primary, .btn-outline-danger, .btn-outline-success{
                      width:30px;
                      height: 30px;
                      // padding:7px;
                      padding:0.1rem 0.7rem;
                      @include border-radius(50%);
                      border:none;
                      position: relative;
                      i,svg{
                        position: absolute;
                        top:50%;
                        left:50%;
                        transform: translate(-50%,-50%);
                        color:$gray-700;
                        font-size: 18px;
                      }

                      &:hover{
                        background-color:$white;
                        i,svg{
                          color:$gray-900;
                        }
                      }
                    }

                    .btn-outline-success{
                      &:hover{
                        i,svg{
                          color:$success;
                        }
                      }
                    }

                    .btn-outline-primary{
                      &:hover{
                        i,svg{
                          color:$primary;
                        }
                      }
                    }


                    .btn-outline-danger{
                      &:hover{
                        i,svg{
                          color:$danger;
                        }
                      }
                    }

                }
            }

           
        }
        
        td{
            height: 55px;
            border-width: 1px;
            color:$gray-800;
            border-color:$gray-100;
            font-weight: 500;
            min-height: 42px;
            vertical-align: middle;
        
        }
      }

  

      .activeRow{
        .table-action{
            display: block;
        }
      }
     
  
      .css-yk16xz-control{
          border-color:$cogent-border;
          &:hover{
            border:1px solid $gray-500;
          }
        }

        .css-9gakcf-option{
          background-color: $cogent;

          &:active{
            background-color: $cogent;
          }
        }
  
      .form-control{
         min-height: 38px;
         border: 1px solid $cogent-border;
         // padding: 15px;
         border-radius: 4px;
         font-weight: 500;
       
         &:hover{
           border:1px solid $gray-500;
           
         }
         &:focus{
           border:1px solid $cogent;
         }
      }
  }

  // .table-footer
  // {
  //   padding-top:10px;
  //   border-top:1px solid $cogent-border;
  // }



}