#topbar-wrapper {
    // box-shadow: 1px 1px 8px 4px $gray-300;

    .btn {
        box-shadow: none;
    }

    .header-content-left {
        margin-left: -15px;
        .logo {
            display: none;
        }
    }
    .header-content-right {
        margin-right: -15px;
    }

    .dropdown-menu {
        &:before {
            content: "";
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 6px solid $white;
            position: absolute;
            display: block;
            top: -6px;
            right: 12px;
        }
    }

    .topbar-dropdown {
        .dropdown-menu {
            width: 260px;
            border-radius: 0 !important;
            padding: 0;
            box-shadow: 1px 1px 8px 4px $gray-300;
            border: none;

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 6px solid #ffffff;
                position: absolute;
                display: block;
                top: -6px;
                right: 20px;
            }
        }

        .dropdown-details {
            position: relative;
            height: 60px;
            margin-bottom: 20px;
            text-align: center;
        }

 
    }

    .topbar-search {
        // margin-right: -4px;

        .dropdown-menu {
            width: 360px;
            top: -6px;
            // max-height  :420px;
            // overflow: auto;

            .menu-list-container {
                max-height: 428px;
                overflow: auto;
            }

            &:before {
                content: "";
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 6px solid $white;
                position: absolute;
                display: block;
                top: -6px;
                left: 180px;
            }

            .dropdown-item {
                &:focus {
                    background-color: lighten($cogent, 72%);
                }

                &:hover {
                    background-color: lighten($cogent, 72%);
                }
            }

            li {
                padding: 14px;
                height: auto;
            }
        }

        .menu-link {
            display: flex;
            flex-direction: row;
            // padding-top: 12px;
            // padding-bottom: 12px;

            .anchor-icon {
                width: 32px;
                height: 32px;
                line-height: 28px;
                text-align: center;
                flex-shrink: 0;
                @include border-radius(50%);
                border: 1px solid $gray-100;
                background-color: $gray-200;
            }

            .menu-box {
                margin-left: 14px;
                .menu {
                    font-size: 13px;
                    font-weight: 500;
                    color: $gray-800;
                }

                .sub-menu {
                    font-size: 12px;
                    font-weight: 400;
                    color: $gray-700;
                }

                .selected {
                    font-weight: 700;
                    color: $gray-900;
                    display: inline;
                }
            }
        }
    }

    // for profile details

    .user-profile {
        margin-top: -7px;
        // padding:0 4px;
       

        &.show {
            background-color: #e6efff;
            @include border-radius(50%);
        }

        & > .btn {
            // width: 45px;
        }

        i {
            margin-top: -5px;
        }

        .dropdown-toggle {
            text-align: center;
            width: 40px;
            height: 40px;
            // padding:2px;
            @include border-radius(50%);
            position: relative;
            -webkit-transition: all 0.3s;
            transition: all 0.3s;

            &:hover {
                background-color: $white;
                // background-color:lighten($primary,42%);
                @include border-radius(50%);
            }

            .avatar {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 34px;
                height: 34px;
                display: inline-block;
                @include border-radius(50%);
            }


        
        }

        .dropdown-menu {
            width: auto;
            margin-top: 10px;
            min-width: 280px;
            border: none;
            //box-shadow      : 1px 1px 8px 1px $gray-300;
            box-shadow: 0px 4px 2px 0px $gray-400;
            padding: 20px 10px;
            background-color: $white;
            @include border-radius(5px !important);
        }

        .user-details {
            position: relative;  
            text-align: center;
            color: $gray-800;
            padding-bottom:6px;
            margin-bottom:6px;
            border-bottom:1px solid $gray-200;

            .avatar {
                position: relative;
                margin: 15px auto 13px;
                width: 100px;
                height: 100px;
                border:1px solid $gray-200;
                @include border-radius(50%);
               

                img{
                    width:100px;
                    height: 100px;
                    @include centerer;
                    @include border-radius(50%);
                    border:1px solid $gray-100;
                }

                &::before{
                    position: absolute;
                    content: "";
                    width:100%;
                    height: 100%;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;                    
                    z-index: 9;  
                    @include border-radius(50%); 
                    opacity: 0;   
                    transition:all 0.2s ease-in;  

                            
                }

                &::after{                   
                    font-family: 'FontAwesome';
                    top: 0;
                    left: 10px;
                    content: "\f030";
                    font-size:24px;
                    color:white;
                    @include centerer;
                    opacity: 0;
                    transition:all 0.2s ease-in;  
                    z-index: 999;

                    
                }

                &:hover{
                    cursor:pointer;
                    &::before{
                        opacity: 0.8;
                        background-color: $gray-600;
                        transition:all 0.3s ease-in-out;
                        border:1px solid rgba($primary,0.2);
                    }

                    &::after{
                        opacity:1;
                    }
                }


            }

            .user-name {
                font-size: 1rem;
                line-height: 1.6rem;
                font-weight: 600;
                margin-bottom: 0px;
                text-transform: capitalize;
            }

           
        }

        .profile-links{
            li{
                padding:6px 4px 6px 8px;
                @include border-radius(4px);
              
                a{
                  font-size: 13px; 
                  padding: 0px; 
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  color:$gray-600;
                  text-decoration: none;
                  i{
                      margin-top:0;
                      margin-right:10px;
                  }
                 
                }

                &:hover{
                    background-color: $gray-200;
                    color:$gray-600;
                    text-decoration: none;
                    cursor:pointer;
                    
                }
            }
        }
    }

    .fav-links {
        margin-top:-3px;
        margin-right: 10px;
        .btn {
            width: 40px;
            // height: 40px;
            color:$gray-700;
            // border: 1px solid $gray-200;
            // background-color: $gray-100;
            @include border-radius(50%);

            // svg{
            //     width:20px;
            //     height:20px;
            // }



            &:hover {
                color: $primary;
                background-color: #fff;
                @include border-radius(50%);
            }


        }

        // &:hover {
        //     .btn {
        //         color: $primary;
        //         background-color: #fff;
        //         @include border-radius(50%);
        //     }
        // }
    }

    .quick-links {
    
        i {
            margin-top: 0;
        }

        .dropdown-toggle{
            i{
                 @include centerer;
                // margin-right:8px;
                
            }

            &:hover{
                .fa-star{
                    // color:#f4cd0c;
                    color:$primary;
                }
            }
        }

        .btn {
            // width: 34px;
            // height: 34px;
            // padding:6px 6px;
            // color: #f4cd0c;
            // margin-top:4px;
            color:$gray-700;
            // border: 1px solid $gray-400;
            // background-color:white;
            @include border-radius(50%);
            // box-shadow:
            // -4px -4px 9px 0 rgba(0, 0, 0, 0.05),
            // 4px 4px 12px rgba(0, 0, 0, 0.05);


            &:hover,&:active,&:focus {
                color: $gray-700;
                background-color: #fff;
                border:1px solid transparent;
                @include border-radius(50%);
            }


        }

        &.show {
            background-color: $white !important;
            @include border-radius(50%);
            border:none;

            .fa-star{
                color:$primary;
            }

            .btn {
                border:1px solid transparent;
            }

        }

        .add-bar {     
            width: 100%;
            height: auto;
            background-color: $gray-100;
            // border-top: 1px solid $gray-100;
            // text-align: center;
            background-color: $white;
            padding: 0 10px;

            .add-new-fav {
                width: 100%;               
                padding: 16px 0px;
                border: none;
                border-top:1px solid $gray-300;
                color:$gray-800;
                background-color:transparent;               
                line-height: 1.2;
                font-size:13px;
                font-weight: 500;
                @include border-radius(0px !important);
                text-align: left;

                &:hover {
                    // background-color: $gray-200;
                    text-decoration: underline;
                }

                i{
                    width:46px;
                    padding-left:12px;
                    color:$gray-600;
                }

                svg{
                    width:46px;
                    font-size: 15px;
                    padding-right:12px;
                    color:$gray-600;
                }
            }
        }

        .dropdown-menu {
            width: auto;
            margin-top: 10px;
            min-width: 260px;
            border: none;
            box-shadow: 0px 4px 2px 0px $gray-400;
            padding: 0px;
            background-color: $white;
            @include border-radius(5px !important);
        }

        .menu-link {
            display: flex;
            flex-direction: row;
            padding: 4px 10px;
            align-items: center;

            .anchor-icon {
                width: 32px;
                height: 32px;
                line-height: 31px;
                text-align: center;
                font-size: 0.75rem;
                flex-shrink: 0;
                @include border-radius(50%);
                border: 1px solid $gray-100;
                background-color: $gray-200;
            }

            .menu-box {
                margin-left: 14px;
                padding: 12px 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-grow: 2;
                align-items: center;
                .menu {
                    font-size: 13px;
                    font-weight: 500;
                    color: $gray-800;
                }

                .selected {
                    font-weight: 700;
                    color: $gray-900;
                    display: inline;
                }

                .remove-fav {
                    padding-right: 8px;
                    color: $gray-600;
                    display: none;

                    &:hover {
                        color: $danger !important;
                    }
                }
                
            }

            &:hover {
                background-color: transparent;

                .menu{
                    text-decoration: underline;
                }
                .menu-box .remove-fav {
             
                    display: inline-block;
                }
            }
           
        }
    }
}

 .menu-link {
            display: flex;
            flex-direction: row;
            padding: 4px 10px;
            align-items: center;

            .anchor-icon {
                width: 32px;
            height: 32px;
            line-height: 31px;
            text-align: center;
            font-size: 0.75rem;
            flex-shrink: 0;
            @include border-radius(50%);
            border: 1px solid $gray-100;
            background-color: $gray-200;

            }

            .menu-box {
                margin-left: 14px;
                padding: 8px 0;
                // display: flex;
                // flex-direction: row;
                // justify-content: space-between;
                flex-grow: 2;
                align-items: center;
                .menu {
                    font-size: 13px;
                    font-weight: 500;
                    color: $gray-800;
                }

                .selected {
                    font-weight: 700;
                    color: $gray-900;
                    display: inline;
                }

                .remove-fav {
                    padding-right: 8px;
                    color: $gray-800;
                    display: none;

                    &:hover {
                        color: $danger;
                    }
                }

            }

            &:hover {
                background-color: $gray-100;
                .menu-box .remove-fav,
                .menu-box .add-fav {
                    display: inline-block;
                }
            }

        }


.add-fav-modal{
//   transform: translateY();
  align-items: flex-start;
  padding-top:80px;
  max-width: 400px;


   .modal-content .modal-body{
       padding-top:15px;
   }

   .modal-header{
       .modal-title{
           padding-left: 5px;
       }

    .close{
        margin-right:-4px;
    }
    }

   .modal-content .modal-body{
       padding:25px 15px 25px 15px;
   }

   #scrollbar_add-fav-scrollbar{
       height: 250px;
   }

    #fav-search {
        // padding-bottom:14px;
        border-bottom:1px solid $gray-100;
        padding:0 15px 14px;

        input{
            border:none;
            padding-left:7px;
            // margin:0 15px;
            border-bottom:1px solid $gray-300;
            @include border-radius(0);
            @include transition(all,0.8s,ease);
    //    -webkit-transition: all 0.8s ease;
    //    -moz-transition: all 0.8s ease;
    //    transition: all 0.8s ease;

            &:focus{
               color:$gray-900;
            }
        }

        .input-group-append{
            margin-right:3px;
        }

        button,span{
            border:transparent;
            border-bottom:1px solid $gray-300;
            @include border-radius(0);
            background-color: transparent;

           
        }
      }

   
      
    .menu-link {
        display: flex;
        flex-direction: row;
        padding: 4px 21px;
        align-items: center;
        width:100%;
        @include transition(all,0.9s,ease);
        // @include border-radius(6px);

        &.selected{
            .anchor-icon{
                background-color: $primary;
                color:$white;
                
            }
        }

        .anchor-icon {
            width: 32px;
            height: 32px;
            line-height: 31px;
            text-align: center;
            font-size: 0.75rem;
            flex-shrink: 0;
            @include border-radius(50%);
            border: 1px solid $gray-100;
            background-color: $gray-200;

           
        }

        .menu-box {
            margin-left: 14px;
            padding: 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex-grow: 2;
            align-items: center;
            .menu {
                font-size: 13px;
                font-weight: 500;
                color: $gray-800;
            }

            .selected {
                font-weight: 700;
                color: $gray-900;
                display: inline;
            }

            .remove-fav {
                padding-right: 8px;
                color: $gray-800;
               display: inline;
                &:hover {
                    color: $danger;
                }
            }

            .add-fav {
                padding-right: 8px;
                color: $gray-600;   
                display: inline;  
                // font-size: 17px;          
                &:hover {
                    color: $primary;
                }
            }
        }

        &:hover {
            background-color: $gray-200;
            cursor:pointer;
           
        }

       
    }
}  

#favourites{
    z-index: 99999999;
}


